import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import RootStore from '../../store/Root'
import classNames from 'classnames'
import { version } from '../../../package.json'
import { observer } from 'mobx-react'
import ReactSelectComponent from '../commonComponent/ReactSelectComponent'
import { USER_ROLE_SUPER_ADMIN } from '../../utils/constant'
import { toast } from 'react-toastify'

interface Props {
    rootStore: RootStore
}

const NavBar: React.FC<Props> = ({ rootStore }) => {
    const { authStore, allOrgStore, appUserStore, addressStore, clientStore, trusteeInfoStore, deedResearchStore, deedFilingStore, matterStore, clientDraftAdjustmentStore, probableStore, visionStore, invoiceInfoStore, planPricingTemplateStore, engagementTemplateStore, audioStore, errorLogStore, emailLogStore, thirdPartyLogStore, iPadLocalDataStore, lawmaticsStore } = rootStore
    const { isUserLoggedIn, userRole, checkShowOrganizationSelection } = authStore

    const [isOpenModel, setIsOpenModel] = useState(false)
    const toggle = () => {
        rootStore.navStore.collapse = !rootStore.navStore.collapse
    }
    const history = useHistory()

    const setOrganization = (data: any) => {
        if (data.value !== authStore.userOrg.value) {
            appUserStore.setIsOpen(false)
            addressStore.setIsOpen(false)
            clientStore.setIsOpen(false)
            trusteeInfoStore.setIsOpen(false)
            deedResearchStore.setIsOpen(false)
            deedFilingStore.setIsOpen(false)
            clientDraftAdjustmentStore.setIsOpen(false)
            probableStore.setIsOpen(false)
            visionStore.setIsOpen(false)
            invoiceInfoStore.setIsOpen(false)
            planPricingTemplateStore.setIsOpen(false)
            engagementTemplateStore.setIsOpen(false)
            emailLogStore.setIsOpen(false)
            thirdPartyLogStore.setIsOpen(false)
            iPadLocalDataStore.setIsOpen(false)
            matterStore.setIsOpen(false)
            audioStore.setIsOpen(false)
            errorLogStore.setIsOpen(false)
            lawmaticsStore.setIsOpen(false)
    
            authStore.setUserOrganization({ _id: data.value, name: data.label }, true)
        }
        setIsOpenModel(!isOpenModel)
        toast.success(`You're now viewing ${data.label}`, { position: 'top-center'})
    }
    

    return <>
        <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow" style={{ zIndex: 101 }}>
            <div className='col-md-3 col-lg-2 mr-0 px-3 d-flex'>
                <NavLink className="navbar-brand " to="/dashboard">{isUserLoggedIn && authStore.userOrg.label} <span className='version'>&nbsp;V.({version})</span></NavLink>

            </div>

            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation" onClick={toggle}>
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={classNames('ml-auto col-md-2 pr-0', { 'd-none': userRole !== USER_ROLE_SUPER_ADMIN || !isUserLoggedIn || !checkShowOrganizationSelection(history.location.pathname) })}>
                <ReactSelectComponent
                    options={allOrgStore.orgListValueLabel}
                    value={authStore.userOrg}
                    handleOnChangeReact={setOrganization}
                    isMenuFixed={true}
                />
            </div>
           
            <ul className="navbar-nav px-3 d-flex ">
                <li className={classNames('nav-item', { 'd-none': isUserLoggedIn })}>
                    <NavLink className="nav-link" to="login">Login</NavLink>
                </li>
            </ul>
        </nav>

    </>
}

export default observer(NavBar)
