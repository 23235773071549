import { action, observable } from 'mobx'
import RootStore from '../Root'
import { addUpdateEngagementTemplate, getDefaultEngagementTemplateDetails, getEngagementTemplates, getSingleEngagementTemplateDetails } from '../../api/vision/EngagementTemplateActions'
import { EngagementTemplateInformation, Dictionary } from '../types.d'
import { initialTableDataWithPaginationDetails, initialEngagementTemplateDetails } from '../InitialValues'
import TextInfoStore from './TexInfo'

interface EngagementTemplateData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<EngagementTemplateInformation>
}

class EngagementTemplateStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public engagementTemplateDetails: EngagementTemplateInformation
    @observable public engagementTemplateData: EngagementTemplateData
    // @observable public defaultEngagementTemplateDetails: EngagementTemplateDefaultInformation
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.engagementTemplateDetails = initialEngagementTemplateDetails
        this.engagementTemplateData = initialTableDataWithPaginationDetails
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.searchItem = ''
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }


    resetEngagementTemplateDetails = (): void => {
        this.isApiError = false
        this.apiErrorMessage = ''
        this.engagementTemplateDetails = initialEngagementTemplateDetails
    }

    //get the list of plans with details
    fetchEngagementTemplates = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.page = page
        this.searchItem = searchItem
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''


        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getEngagementTemplates(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.engagementTemplateData = response.data.data
            this.resetEngagementTemplateDetails()
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the plan details in planDetails variable for show in update plan page
    setEngagementTemplateDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSingleEngagementTemplateDetails(data).then((response) => {
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.engagementTemplateDetails = response.data.data
            // const { left_text, right_text, email_text } = response.data.data
            // const defaultEngagementDetails = {
            //     left_text,
            //     right_text,
            //     email_text
            // }
            // console.log(defaultEngagementDetails, 'default')
            // this.defaultEngagementTemplateDetails = defaultEngagementDetails
            this.isLoading = false
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    fetchDefaultEngagementDetails = (): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getDefaultEngagementTemplateDetails().then((response) => {
            
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            if (response.data.data !== null) {
                const { left_text, right_text, email_text, is_default } = response.data.data
                const defaultEngagementDetails = {
                    name: '',
                    _id: '',
                    left_text,
                    right_text,
                    email_text,
                    is_default
                }
                this.engagementTemplateDetails = defaultEngagementDetails
            }
            this.isLoading = false
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
    

    //update or delete the plan details of given plan_id
    addUpdateEngagementTemplate = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdateEngagementTemplate(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchEngagementTemplates(this.page, this.searchItem)
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    addNewTextInfo(single_text: string, married_text: string, modalTitle: string): void {
        const data = {
            single_text: single_text,
            married_text: married_text
        }
        const descriptionInfo = new TextInfoStore(data)
        if (modalTitle.includes('Left')) {
            this.engagementTemplateDetails.left_text.push(descriptionInfo)
        } else {
            this.engagementTemplateDetails.right_text.push(descriptionInfo)

        }
    }
}

export default EngagementTemplateStore
