import React, { useEffect, useState } from 'react'
import Container from '../layout/Container'
import { observer } from 'mobx-react'
import Table from '../table/Table'
import { Props, UnansweredDetails, ProgressProp } from '../../store/types.d'
import { Link, useParams, useHistory } from 'react-router-dom'
import uuid from 'react-uuid'
import io from 'socket.io-client'
const taskId = uuid()
const socket = io(process.env.REACT_APP_BASEURL, {
    query: { taskId }
})
 
import { convertToTitleCase, dateFormat, dateFormatWithTime, FormateDate, ShowDetails } from '../commonComponent/commonFuction'
import { Cell } from '../table/TableCellText.d'
import { Breadcrumb } from '../commonComponent/BreadcrumbComponent'
import DatePicker from 'react-datepicker'
import { addUpdateMatterDocumentDate, setCMSCountyInMatter, sendMailOfNFToClient, getAutomaticEmailStatusOfMatter } from '../../api/MatterAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFilePdf, faImages } from '@fortawesome/free-regular-svg-icons'
import { faDownload, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from '../commonComponent/Modal'
import { initialClientDraftAdjustment, initialTemplate } from '../../store/InitialValues'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import moment from 'moment'
import TableCellTo from '../emailLogs/TableCellTo'
import TableCellYesNo from '../emailLogs/TableCellYesNo'
import { TableCellErrorDetails } from '../emailLogs/TableCellErrorData'
import { TableCellEmailDetails } from '../emailLogs/TableCellEmailDetail'
import TableCellAutoOrManual from './TableCellAutoOrManual'
import Countdown from 'react-countdown'
import { ArrowClockwise } from 'react-bootstrap-icons'
import ClientDraftAdjustmentModel from './ClientDraftAdjustmentModel'
import ClientDraftAdjustmentStore from '../../store/SingleClientDraftAdjustment'
import { USER_ROLE_SUPER_ADMIN } from '../../utils/constant'
import { TableCellCheckboxCompleted, TableCellCheckboxReview, TableCellCheckboxWorking, TableCellCheckboxDrafted, TableCellCheckboxPending, TableCellCreatedDetails } from '../clientDraftAdjustment/TableCell'
import { ProgressLoading } from '../commonComponent/DotLoader'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { englishStrings } from '../lawmatics/EnglishString'
import { ShowLastUpdateDate } from '../commonComponent/ShowLastUpdatedTime'

const TableCellNameWithEditIndicator: React.FC<Cell> = (props) => {
    let value = props.value || ''
    const data = props.data
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    return (
        <>
            {value}{data.document.type ? ` - ${convertToTitleCase(data.document.type)}` : ''}
            {(data.document.is_document_edited) && <span className='float-right text-secondary' title='Edited Template'><FontAwesomeIcon icon={faEdit} /></span>}
            {(data.document.isImagesAvailable) && <Link to={`${data.document.links[0][2].link}#deed-images`} className='mr-2 ml-2 float-right text-secondary ' title='Images Available'><FontAwesomeIcon icon={faImages} /></Link>}
        </>
    )
}

const TableCellDeedType: React.FC<Cell> = (props) => {
    let value = props.value || ''
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }
    return (
        <>
            {convertToTitleCase(value)}
        </>
    )
}

const TableCellPreviewNew: React.FC<Cell> = (props) => {

    let value = props.value || ''
    const isAdmin = props.data.isAdmin
    const disableDownloadAllDoc = props.data.disableDownloadAllDoc
    const isNonDeed = props.data.isNonDeed || false
    // Convert boolean values to string so that they are visible in table
    if (value === true || value === false) {
        value = value.toString()
    }

    const { generateDocx, generateNewPDF, document, templateDetails, generateTableOfContentPDF, generatePDFWithPageReset } = props.data

    const previewRowCell: Array<JSX.Element> = []

    // const cellData = clientDocuments.find((doc) => doc.document_id === value)
    const cellData = document
    if (cellData !== undefined) {
        for (let i = 0; i < cellData.links.length; i++) {
            const colCell: Array<JSX.Element> = []
            for (let j = 0; j < cellData.links[i].length; j++) {
                const linkData = cellData.links[i][j]
                const uuidForId = uuid()
                const tooltip = _.get(linkData, 'tooltip', {})
                const { pdfOrDoc, label } = linkData
                const baseClass = 'mr-2'
                const isDisabled = ((pdfOrDoc === 'pdf' || pdfOrDoc ==='pdfPageReset') && disableDownloadAllDoc && isNonDeed) ? true : false
                const disableLinkClass = ((pdfOrDoc === 'pdf' || pdfOrDoc ==='pdfPageReset') && disableDownloadAllDoc && isNonDeed) ? `${baseClass} disabled-link` : baseClass
                const className = label.includes('Delete') ? `${baseClass} text-danger float-right` : disableLinkClass

                colCell.push(
                    <React.Fragment key={uuid()} >
                        {(linkData.pdfOrDoc !== 'tableOfContents' || (linkData.pdfOrDoc === 'tableOfContents' && isAdmin)) && !isDisabled && <Link id={uuidForId} key={uuid()} className={className} to={linkData.link} onClick={async () => {
                            if (linkData.arguments !== undefined) {
                                if (linkData.pdfOrDoc === 'doc') {
                                    await generateDocx(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                } else if (linkData.pdfOrDoc === 'pdf') {
                                    await generateNewPDF(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                } else if (linkData.pdfOrDoc === 'pdfPageReset') {
                                    await generatePDFWithPageReset(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                } else if (linkData.pdfOrDoc === 'tableOfContents') {
                                    await generateTableOfContentPDF(linkData.arguments.matterId, value, linkData.arguments.forSpouse, linkData.arguments.UUID)
                                }
                            } else if (linkData.label.includes('Delete')) {
                                const EditIdPosition = cellData.links[i].length > 4 ? 2 : 1
                                const linkParts = cellData.links[i][j - EditIdPosition].link.split('/')
                                const htmlId = _.last(linkParts)
                                const sure = confirm(`Are you sure you want to delete the edited HTML of ${document.name} ?`)
                                if (sure) {
                                    templateDetails.deleteHTML(htmlId, (flag: string) => {
                                        if (flag !== 'false') {
                                            toast.success(flag)
                                            window.location.reload()
                                        } else {
                                            toast.error('Sorry document not deleted please try again !')
                                        }
                                    })
                                }
                            }
                        }}>{linkData.pdfOrDoc === 'pdfDeprecated' ? <FontAwesomeIcon icon={faFilePdf} /> : linkData.label}</Link>}

                        {isDisabled && <>
                            <span id={uuidForId} >
                                <Link to="#" className='disabled-link mr-2'>{label}</Link>
                            </span>
                            <ReactTooltip anchorId={uuidForId} >Please complete the CDA(s) for this matter</ReactTooltip>
                        </>}
                        {!_.isEmpty(tooltip) && linkData.pdfOrDoc !== 'pdfDeprecated' ? <ReactTooltip anchorId={uuidForId} >
                            <div>
                                Created: {moment(tooltip.createdAt).format('MM/DD/YYYY h:mm a')}
                            </div>
                            <div>
                                Updated: {moment(tooltip.updatedAt).format('MM/DD/YYYY h:mm a')}
                            </div>
                        </ReactTooltip> : !_.isEmpty(tooltip) && linkData.pdfOrDoc === 'pdfDeprecated' && <ReactTooltip anchorId={uuidForId} >{tooltip}</ReactTooltip>
                        }
                    </React.Fragment>
                )
            }
            previewRowCell.push(
                <div key={uuid()}>
                    {colCell}
                </div>
            )
        }
    }
    return (<div key={uuid()}>{previewRowCell}</div>)
}

const TableCellCdaNote: React.FC<Cell> = (props) => {
    const value = props.value || ''
    const data = props.data || {}
    const onActionClicked = props.onTableAction 

    return <>
        {value}<br/>
        <Link to='#' onClick={() => { onActionClicked && onActionClicked('editCda', value, data) }} title='Edit a Client Draft Adjustment'>Edit</Link>
    </>
}

interface MattersDocumentPageParams {
    clientId: string
    matterId: string
}

const MattersDocumentPage: React.FC<Props> = ({ rootStore }) => {
    const { clientStore, templateStore, setTitle, authStore, clientDraftAdjustmentStore } = rootStore
    const { isAdmin, isDeedUser } = authStore
    const {
        isApiError,
        apiErrorMessage,
        isLoading,
        matter,
        setCurrentMatter,
        generateDocx,
        allDocumentsInZipExport,
        generateNewPDF,
        previewDocument,
        documentsHTMLInZip,
        generateTableOfContentPDF,
        generatePDFWithPageReset,
        fetchEmailLogsOfMatter,
        emailLogs,
        searchKeyword,
        setSearchKeyword,
        filteredDocumentsData,
        clientDraftAdjustments,
        fetchClientDraftAdjustment,
        addUpdateClientDraftAdjustment,
        disableDownloadAllDoc,
        cdaCountForMatter,
        resetCurrentMatter
    } = clientStore
    const { templateDetails } = templateStore

    const { clientId, matterId } = useParams<MattersDocumentPageParams>()

    const [startDate, setStartDate] = useState<Date>()
    const [action, setAction] = useState<string>('all')
    const [CMSCounty, setCMSCounty] = useState<string>()
    const [automaticEmailStatus, setAutomaticEmailStatus] = useState('No')
    const [countdownDate, setCountdownDate] = useState('')
    const [emailSentAt, setEmailSentAt] = useState('')
    const [showCountDown, setShowCount] = useState(false)

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showUnansweredDetails, setShowUnansweredDetails] = useState(false)
    const [showEmailLogs, setShowEmailLogs] = useState(false)
    const [showCdaModal, setShowCdaModal] = useState(false)
    const [unansweredDetails, setUnansweredDetails] = useState<Array<UnansweredDetails>>([])
    const [zipLink, setZipLink] = useState<string>('')

    const [clientDraftAdjustment, setClientDraftAdjustment] = useState<ClientDraftAdjustmentStore>()
    const [modalTitle, setModalTitle] = useState('')
    const [isAddAction, setIsAddAction] = useState(false)

    const [progress, setProgress] = useState<ProgressProp>()
    // const [progressDoc, setProgressDoc] = useState({})
    const formatter = buildFormatter(englishStrings)

    const disableSendNFButton = (showCountDown && automaticEmailStatus === 'Pending') || (_.isEmpty(matter.client.email) && ((matter.type === 'INDIVIDUAL' || matter.type === 'INDIVIDUAL_MARRIED') || (matter.type === 'JOINT' && _.isEmpty(matter.client.spouse_email))))
    const history = useHistory()

    useEffect(()=> {
        resetCurrentMatter()
    }, [resetCurrentMatter])

    useEffect(() => {
        if (matter._id === '' || matter._id !== matterId) {
            setCurrentMatter(matterId)
            setTitle(matter.name)
        }

        {!isDeedUser && 
            getAutomaticEmailStatusOfMatter({ matter_id: matterId}).then((response) => {
                if (response.data.success === 1) {
                    setAutomaticEmailStatus(response.data.data.status)
                    if(response.data.data.status === 'Yes') {
                        let autoEmailSentAt = response.data.data.data.automatic_mail_sent_at 
                        autoEmailSentAt = (autoEmailSentAt === undefined ? '' : autoEmailSentAt)
                        setEmailSentAt(autoEmailSentAt)
                    }
                    if(response.data.data.status === 'Pending') {
                        setShowCount((response.data.data.yesNo === 'Yes'))
                        setCountdownDate(response.data.data.data.date)
                    }
                }
            })
        }
        templateDetails.set(initialTemplate)
        previewDocument.set(initialTemplate)
        if (matter._id !== '' && matter._id === matterId) {
            setTitle(`${matter.name} | Matter`)
            if (matter.generate_date !== '' && startDate === undefined) {
                const showDate = matter.generate_date.replace(/-/g, '/').split('T')
                setStartDate(new Date(showDate[0]))
            }
            if (matter.cms_county !== undefined && matter.cms_county !== '' && CMSCounty === undefined) {
                setCMSCounty(matter.cms_county)
            }
            { !isDeedUser && fetchClientDraftAdjustment(matterId) }
        }
    }, [matter, matterId, previewDocument, setCurrentMatter, startDate, templateDetails, setTitle, setCMSCounty, CMSCounty, setAutomaticEmailStatus, isDeedUser, fetchClientDraftAdjustment])

    useEffect(() => {
        socket.on('progress', (newProgress) => {
            if (newProgress.taskId && newProgress.taskId === taskId) {
                setProgress({progress: newProgress.progress, docName: newProgress.message, currentDoc: newProgress.currentDoc})
                if (newProgress.progress === 100) {
                    setProgress(undefined)
                    setAction('all')
                }
            }
        })
    }, [])

    const tableData = filteredDocumentsData
    const handleOnChangeOfDatePicker = (date: any) => {
        const requestData = {
            matter_id: matterId,
            generate_date: date.toString()
        }
        addUpdateMatterDocumentDate(requestData).then((response) => {
            if (response.data.success === 1) {
                setStartDate(date)
                setCurrentMatter(matterId)
                toast.success(response.data.message)
            }
        }).catch((e) => {
            console.log(e)
            toast.error(e.message)
        })
    }

    const handleOnApplyCounty = () => {
        const requestData = {
            _id: matterId,
            cms_county: CMSCounty
        }
        if (CMSCounty !== undefined) {
            setCMSCountyInMatter(requestData).then((response) => {
                if (response.data.success === 1) {
                    setCurrentMatter(matterId)
                    toast.success(response.data.message)
                }
            }).catch((e) => {
                console.log(e)
            })
        }
    }

    const handleOnClickOfSendMsg = () => {
        const requestData = {
            matter_id: matterId
        }

        const sure = matter.package_slug === 'amendment' ? confirm('Are you sure you want to send mail of Name And Fiduciary document to client ?') : confirm('Are you sure you want to send mail of Name And Fiduciary & other selected documents to client ?')
        if (sure) {
            sendMailOfNFToClient(requestData).then((response) => {
                if (response.data.success === 1) {
                    setCurrentMatter(matterId)
                    toast.success(response.data.message)
                }
            }).catch((e) => {
                toast.success(e.message)
            })
        }
    }


    const onActionClicked = (action: string, data: any) => {
        if (action === 'addCda') {
            setClientDraftAdjustment(new ClientDraftAdjustmentStore(initialClientDraftAdjustment))
            const title = `CDA ${cdaCountForMatter + 1} - ${matter.name}`
            setModalTitle(title)
            setIsAddAction(true)
            openCloseCdaModel()
        }
        if (action === 'editCda') {
            setClientDraftAdjustment(data)
            const title = data.title ? data.title : `CDA - ${matter.name}`
            setModalTitle(title)
            setIsAddAction(false)
            openCloseCdaModel()
        }
    }
    const compareDate: any = async (date: Date | undefined, generateDate: string, action: string) => {

        const generateDateArray = generateDate.replace(/-/g, '/').split('T')
        const generateDateInIstFormate = new Date(generateDateArray[0])
        if (date?.getTime() == generateDateInIstFormate.getTime()) {
            setShowConfirmModal(true)
            setAction(action)
        } else if (date?.getTime() != generateDateInIstFormate.getTime()) {
            await handleOnChangeOfDatePicker(date)
            setShowConfirmModal(true)
            setAction(action)
        }
    }

    const tableDataOfDeed = matter.deed_links !== undefined ? matter.deed_links?.map((item) => {
        return {
            _id: item.deed._id,
            document: { ...item.deed, links: item.links },
            generateDocx,
            generateNewPDF,
            templateDetails,
            setCurrentMatter,
            generateTableOfContentPDF,
            generatePDFWithPageReset
        }
    }) : []

    const handleDocSearch = (e: any) => {
        setSearchKeyword(e.target.value)
    }

    const openCloseConfirmModal = () => {
        setShowConfirmModal(!showConfirmModal)
    }

    const openCloseEmailLogs = () => {
        setShowEmailLogs(!showEmailLogs)
    }

    const openCloseCdaModel = () => {
        setShowCdaModal(!showCdaModal)
    }

    const openCloseUnansweredDetails = () => {
        setShowUnansweredDetails(!showUnansweredDetails)
    }

    const onSaveUnansweredModel = () => {
        openCloseUnansweredDetails()
        window.open(`${process.env.REACT_APP_BASEURL}/${zipLink}`, '_blank')
    }

    const onSaveModal = async () => {
        allDocumentsInZipExport(matterId, taskId, action, (response) => {
            if(response !== undefined && response.unansweredDetail && response.path) {
                if(response.unansweredDetail.length !== 0) {
                    setUnansweredDetails(response.unansweredDetail)
                    setZipLink(response.path)
                    openCloseUnansweredDetails()
                } else {
                    window.open(`${process.env.REACT_APP_BASEURL}/${response.path}`, '_blank')
                }
            }
        })
        openCloseConfirmModal()
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            getAutomaticEmailStatusOfMatter({ matter_id: matterId}).then((response) => {
                if (response.data.success === 1) {
                    setAutomaticEmailStatus(response.data.data.status)
                    setEmailSentAt(response.data.data.data.automatic_mail_sent_at)
                    if(response.data.data.status === 'Pending') {
                        setShowCount((response.data.data.yesNo === 'Yes'))
                        setCountdownDate(response.data.data.data.date)
                    }
                }
            })
            return <></>
        } else {
            // Render a countdown
            return <span>{minutes}:{seconds}</span>
        }
    }

    const onRefreshClick = () => {
        setCurrentMatter(matterId)
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }
    }

    const deedUserLinks = [{ name: 'Documents', isActive: true }]

    const adminSpecificLinks = [{ name: 'Clients',  to: '/clients/' }, { name: 'Matters',  to: `/clients/${clientId}` }]

    const allLinks = [...adminSpecificLinks, ...deedUserLinks]
    const links = isDeedUser ? deedUserLinks : allLinks

    const buttons = <div className="ml-auto">
        <button className='btn btn-sm btn-outline-primary mr-2' onClick={onRefreshClick}><ArrowClockwise /> Refresh</button>
        { !isDeedUser && <Link to={`/clients/${clientId}`} className='btn btn-sm btn-outline-primary'>Back</Link> }
    </div>

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div>
            <Breadcrumb links={links} buttons={buttons} isMatterPage={true} />

            <div className="card">
                <div className="card-header">
                    Documents of Matter
                    <div className='float-right'>
                    </div>
                </div>

                <div className="card-body">
                    <div className='row'>
                        <div className='col'>

                            {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}
                            { action === 'zipInPDF' && isLoading && <ProgressLoading progress={progress?.progress || 0} docName={progress?.docName || 'Loading...'} currentDoc={progress?.currentDoc || ''} /> }
                            <div>
                                <b>Client Details</b> <ShowLastUpdateDate date={matter.client.updatedAt} id='clientLastUpdate' />:
                                <hr />
                                <ShowDetails key={uuid()} title='First Name' value={matter.client.first_name} />
                                <ShowDetails key={uuid()} title='Middle Name' value={matter.client.middle_name} />
                                <ShowDetails key={uuid()} title='Last Name' value={matter.client.last_name} />
                                <ShowDetails key={uuid()} title='Gender' value={matter.client.gender} />
                                <ShowDetails key={uuid()} title='Street' value={matter.client.street} />
                                <ShowDetails key={uuid()} title='City' value={matter.client.city} />
                                <ShowDetails key={uuid()} title='State' value={matter.client.state.name} />
                                <ShowDetails key={uuid()} title='Country' value={matter.client.country} />
                                <ShowDetails key={uuid()} title='County' value={matter.client.county} />
                                <ShowDetails key={uuid()} title='County Reference' value={matter.client.county_ref} />

                                { !isDeedUser && <>
                                    <ShowDetails key={uuid()} title='Mobile Number' value={matter.client.phone_numbers?.mobile || ''} />
                                    <ShowDetails key={uuid()} title='Home Contact Number' value={matter.client.phone_numbers?.home || ''} />
                                    <ShowDetails key={uuid()} title='Work Contact Number' value={matter.client.phone_numbers?.work || ''} />
                                    <ShowDetails key={uuid()} title='Email Address' value={matter.client.email || ''} />
                                    <ShowDetails key={uuid()} title='Spouse Email Address' value={matter.client.spouse_email || ''} />
                                    <hr />
                                    <b>Matter Details</b> <ShowLastUpdateDate date={matter.updatedAt} id="matterLastUpdated"/>:
                                    <hr />
                                    <ShowDetails key={uuid()} title='Name' value={matter.name} />
                                    <ShowDetails key={uuid()} title='Type' value={matter.type} />
                                    <ShowDetails key={uuid()} title='Added By' value={matter.added_by} />
                                    <ShowDetails key={uuid()} title='Modifier Name' value={matter.modifier_name} />
                                    <ShowDetails key={uuid()} title='State' value={matter.state_name} />
                                    <ShowDetails key={uuid()} title='County' value={matter.county} />
                                    <ShowDetails key={uuid()} title='Package' value={matter.package} />
                                    <ShowDetails key={uuid()} title='Note' value={matter.note} />
                                    <ShowDetails key={uuid()} title='iPad Created At' value={dateFormatWithTime(matter.created_at_ipad)} />
                                    <ShowDetails key={uuid()} title='Created At' value={dateFormatWithTime(matter.createdAt)} />
                                    <ShowDetails key={uuid()} title='Updated At' value={dateFormatWithTime(matter.updatedAt)} />
                                    <ShowDetails key={uuid()} title='Signing Date (iPad)' value={matter.signing_date} />
                                    {(localStorage.getItem('userRole') === USER_ROLE_SUPER_ADMIN) &&
                                        <>
                                            <hr />
                                            <b>Lawmatics Information: </b>
                                            <hr />

                                            <ShowDetails key={uuid()} title='Matter Id' value={matter.lawmatics_matter_id} 
                                                setLink={
                                                    <a 
                                                        href={`${process.env.REACT_APP_LAWMATICS_MATTER_URL}/${matter.lawmatics_matter_id}/activities`} 
                                                        target="_blank" 
                                                        rel="noopener noreferrer" 
                                                        className='ml-auto display-6' 
                                                        title='Lawmatics Matter Id'
                                                    ><FontAwesomeIcon icon={faExternalLinkAlt} />
                                                    </a>
                                                } />
                                            <ShowDetails key={uuid()} title='Contact Id' value={matter.lawmatics_contact_id} setLink={
                                                <a 
                                                    href={`${process.env.REACT_APP_LAWMATICS_CONTACT_URL}/${matter.lawmatics_contact_id}/activities`} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    className='ml-auto display-6' 
                                                    title='Lawmatics Contact Id'
                                                ><FontAwesomeIcon icon={faExternalLinkAlt} />
                                                </a>
                                            }/>
                                            <ShowDetails key={uuid()} title='Probable Uid' value={matter.zephyr_probable_uid} />
                                        </>}
                                    <hr />
                                    <b>Automated Email to Client of N&F Doc:</b>
                                    <hr />
                                    <div className='row row-hover'>
                                        <div className='col-sm-4'>
                                            <b>Has an Automated Email Been Sent?</b>
                                            
                                        </div> :
                                        <div className='col'>
                                            {showCountDown === false && automaticEmailStatus === 'Pending' ? 'No' : automaticEmailStatus } { showCountDown && automaticEmailStatus === 'Pending' && <>
                                                (Email will be sent in <Countdown date={new Date(new Date(countdownDate).getTime() + (5 * 60 * 1000))} renderer={renderer} /> mins)
                                            </> }
                                        </div>
                                    </div>
                                    {automaticEmailStatus === 'Yes' && emailSentAt !== '' && <ShowDetails key={uuid()} title='Automated Email Dispatched At' value={dateFormatWithTime(emailSentAt)} />}
                                    <hr />
                                    <div className='row mt-4'>
                                        <div className="col col-md-7">
                                            {showUnansweredDetails && <Modal 
                                                show={showUnansweredDetails}
                                                modalTitle={'Unanswered docs found'}
                                                openCloseModal={openCloseUnansweredDetails}
                                                onSaveModal={onSaveUnansweredModel}
                                                modalDialogClass='modal-lg'
                                                isScrollable={true}
                                                cancelButton={true}
                                                isSaveButton={true}
                                                saveButtonName={'Proceed to Download'}
                                                closeIcon={false}
                                                cancelButtonName='Close'
                                            >
                                                <table width='100%' className='table-sm table-hover table-bordered text-nowrap'>
                                                    <tr>
                                                        <th>
                                                            Document
                                                        </th>
                                                        <th>
                                                            Unanswered
                                                        </th>
                                                    </tr>
                                                    {unansweredDetails && unansweredDetails.map((ans) => {
                                                        if(ans.count !== 0) {
                                                            return <tr key={uuid()}>
                                                                <td width='70%'>{ans.name}</td>
                                                                <td>{ans.count}</td>
                                                            </tr>
                                                        }
                                                    })}
                                                </table>
                                            </Modal>}
                                            {showConfirmModal ? (
                                                <Modal show={showConfirmModal} modalTitle='Confirm Date' openCloseModal=
                                                    {openCloseConfirmModal} onSaveModal={onSaveModal} saveButtonName='Confirmed' modalDialogClass='modal-dialog-centered' >
                                                    <div className='text-wrap'>
                                                        Are you sure you want to Download Documents for the date {dateFormat(matter.generate_date)} ?
                                                    </div>
                                                </Modal>
                                            ) : null}
                                            <div>
                                                {matter.is_locked == true && <button className="btn btn-warning mr-2 link-download" onClick={async () => {
                                                    const sure = confirm('Are you sure you want to download edited documents of this matter ?')
                                                    if (sure) {
                                                        documentsHTMLInZip(matter._id)
                                                    }
                                                }}>
                                                    <FontAwesomeIcon icon={faDownload} /> Download Highlights
                                                </button>}

                                                <button className="btn btn-primary mr-2" role="button" id="dropdownMenuLink1" disabled={disableDownloadAllDoc} onClick={async () => {
                                                    compareDate(startDate, matter.generate_date, 'zipInPDF')
                                                }}>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </button>
                                                {disableDownloadAllDoc && <ReactTooltip place='top-start' anchorId='dropdownMenuLink1'>
                                                    <span className='z-3'> Please complete the CDA(s) for this matter</span>
                                                </ReactTooltip>}

                                                <button className='btn btn-primary mr-2 link-download' data-toggle="tooltip" title={disableSendNFButton ? 'No email found' : ''} disabled={disableSendNFButton} onClick={async () => {
                                                    handleOnClickOfSendMsg()
                                                }}>
                                                    { matter.package_slug === 'amendment' ? 'Send N&F Doc' : 'Send N&F and Healthcare Docs'}
                                                </button>
                                                <br/>
                                                <button className='btn btn-primary link-download mt-2' onClick={async () => {
                                                    if(showEmailLogs == false) {
                                                        fetchEmailLogsOfMatter(matterId)
                                                    }
                                                    openCloseEmailLogs()
                                                }}>
                                                    { showEmailLogs == true ? 'Hide' : 'Show'} Email Logs
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col col-md-5 d-flex justify-content-end'>
                                            <table className=''>
                                                <tr>
                                                    <td>
                                                        <small className='font-small'>Signing County:</small>
                                                    </td>
                                                    <td>
                                                        <input type="text" className='mr-2' value={CMSCounty} onChange={(e) => setCMSCounty(e.target.value)} />
                                                        <button className='btn btn-sm btn-outline-primary px-20' disabled={CMSCounty === undefined} onClick={() => handleOnApplyCounty()}>Apply</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <small className='font-small'>Signing Date:</small>
                                                    </td>
                                                    <td>
                                                        <DatePicker
                                                            selected={startDate}
                                                            onChange={handleOnChangeOfDatePicker} >
                                                            <div className='text-muted pl-1'>Selected date will reflect in the documents</div>
                                                        </DatePicker>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div> 
                                </>}
                            </div> 
                            <br />
                            <hr/>
                            {showEmailLogs && <div>
                                <b>Email Logs</b>
                                <div className='table-responsive mt-3'>
                                    <Table
                                        isLoading={action !== 'zipInPDF' && isLoading}
                                        unique_key='_id'
                                        columns={[
                                            { name: 'user_id[0].name', title: 'Sent By' },
                                            { name: 'type', title: 'Sent', component: TableCellAutoOrManual },
                                            { name: 'to', title: 'To', component: TableCellTo },
                                            { name: 'sent_status', title: 'Is Sent', component: TableCellYesNo },
                                            { name: 'lawmatics_note_added', title: 'Lawmatics Note Added', component: TableCellYesNo },
                                            { name: 'createdAt', title: 'Created', component: FormateDate },
                                            { name: '_id', title: 'Details', component: TableCellEmailDetails },
                                            { name: 'error', title: 'Request Data', component: TableCellErrorDetails },
                                        ]}
                                        data={emailLogs}
                                    ></Table>
                                </div>
                                <hr/>
                            </div>}
                            {!isDeedUser && <>
                                <div>
                                    <div className="row ml-auto">
                                        <div>
                                            <b className='d-flex align-items-center'>
                                        Client Draft Adjustments&nbsp;{matter.lawmatics_matter_id && <a 
                                                    href={`${process.env.REACT_APP_LAWMATICS_MATTER_URL}/${matter.lawmatics_matter_id}/activities`} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    className='ml-auto display-6' 
                                                    title='Lawmatics Matter Id'
                                                >Lawmatics <FontAwesomeIcon icon={faExternalLinkAlt} />
                                                </a>}
                                            </b>
                                        </div>
                                        <button className='btn btn-primary ml-auto mx-3' onClick={() => { onActionClicked('addCda', clientDraftAdjustment)}}>Add a Client Draft Adjustment</button>
                                    </div>
                                    <div className='table-responsive mt-3 table-sm'>
                                        <Table
                                            isLoading={action !== 'zipInPDF' && (isLoading || clientDraftAdjustmentStore.isLoading)}
                                            unique_key='_id'
                                            columns={[
                                                { name: 'is_working', title: 'Working', component: TableCellCheckboxWorking },
                                                { name: 'is_pending', title: 'Pending', component: TableCellCheckboxPending },
                                                { name: 'is_drafted', title: 'Drafted', component: TableCellCheckboxDrafted },
                                                { name: 'is_review', title: 'Reviewed', component: TableCellCheckboxReview },
                                                { name: 'is_completed', title: 'Completed', component: TableCellCheckboxCompleted },
                                                { name: 'note', class:'wrap-cell', title: 'Requested Adjustment', component: TableCellCdaNote },
                                                { name: 'created_by', title: 'Created', component: TableCellCreatedDetails }
                                            ]}
                                            onTableAction={onActionClicked}
                                            data={clientDraftAdjustments.map((item) => {
                                                return { ...item, clientDraftAdjustmentStore, matterId, matter_name: matter.name }
                                            })}
                                        ></Table>
                                    </div>
                                </div>
                                {showCdaModal && <ClientDraftAdjustmentModel showCdaModal={showCdaModal} modalTitle={modalTitle} note='' matterId={matterId} openCloseCdaModel={openCloseCdaModel} targetData={clientDraftAdjustment} addUpdateClientDraftAdjustment={addUpdateClientDraftAdjustment} isActionAdd={isAddAction}></ClientDraftAdjustmentModel>
                                } </>
                            }
                            <hr/>
                            { !isDeedUser && <>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <b>Documents</b> <ShowLastUpdateDate date={matter.last_answer_updated_at} id="answerLastUpdatedDate"/>:
                                        <div style={{ fontSize: '12px' }}><b>Note:</b> The icon <span className='text-secondary'><FontAwesomeIcon icon={faEdit} /></span> indicates the document is updated for this client.</div>
                                    </div>
                                    <div>
                                        <input className="form-control" name="search" type="text" placeholder="Search Documents" aria-label="Search" onChange={handleDocSearch} value={searchKeyword} />
                                    </div>
                                </div>

                                <div className='table-responsive mt-3 table-sm'>
                                    <Table
                                        isLoading={action !== 'zipInPDF' && isLoading}
                                        unique_key={uuid()}
                                        columns={[
                                            { name: 'document.name', title: 'Document Name', component: TableCellNameWithEditIndicator, class: 'w-50' },
                                            { name: 'document._id', title: 'View', component: observer(TableCellPreviewNew) }
                                        ]}
                                        data={tableData.map((item) => {
                                            return { ...item, isAdmin, disableDownloadAllDoc, isNonDeed: true }
                                        })}
                                    ></Table>
                                </div>
                            </>}

                            {!_.isEmpty(tableDataOfDeed) && <div>
                                <b>Deeds</b>
                                <div className='table-responsive mt-3 table-sm'>
                                    <Table
                                        isLoading={action !== 'zipInPDF' && isLoading}
                                        unique_key={uuid()}
                                        columns={[
                                            { name: 'document.name', title: 'Document Name - Type', component: TableCellNameWithEditIndicator, class: 'w-50' },
                                            { name: 'document._id', title: 'View', component: observer(TableCellPreviewNew) }
                                        ]}
                                        data={tableDataOfDeed}
                                    ></Table>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(MattersDocumentPage)