import axios, { AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const getClients = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/`
    return axios.post(url, requestData)
}

export const getClientDetail = (clientId: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/detail/${clientId}`
    return axios.post(url)
}

export const getClientMatters = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/matters`
    return axios.post(url, requestData)
}

export const getTemplateDetailsWithValues = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/preview`
    return axios.post(url, requestData)
}

export const getGeneratedDocx = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/download`
    return axios.post(url, requestData)
}

export const getHTMLForDocument = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/html/download`
    return axios.post(url, requestData)
}

export const zipDocuments = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/all-document`
    return axios.post(url, requestData)
}

export const lengthOfGroupAnswer = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/get-group-length`
    return axios.post(url, requestData)
}

export const getGeneratedNewPDF = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/new-pdf/download`
    return axios.post(url, requestData)
}

export const getGeneratedTableOfContentsPDF = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/table-of-contents/download`
    return axios.post(url, requestData)
}

export const checkForHTMLDocument = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/is-edited-HTML`
    return axios.post(url, requestData)
}

export const getClientMattersBasic = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/client-matters`
    return axios.post(url, requestData)
}

export const getMatterDocuments = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matter-documents`
    return axios.post(url, requestData)
}

export const zipHTMLDocuments = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/document/HTML/zip`
    return axios.post(url, requestData)
}

export const getPDFWithResetPage = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/pdf-reset-page/download`
    return axios.post(url, requestData)
}
