import { action, observable } from 'mobx'
import RootStore from '../Root'
import { addUpdatePlanPricingTemplate, getPlanPricingTemplates, getPlanPricingForDefault, getSinglePlanPricingTemplateDetails } from '../../api/vision/PlanActions'
import { PlanPricingTemplateInformation, Dictionary, ValueLabel, ExistPlan } from '../types.d'
import { initialTableDataWithPaginationDetails, initialSinglePlanPricingTemplate } from '../InitialValues'
import SinglePlanPricingTemplateStore from './SinglePlanPricingStore'


interface PlanPricingData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<PlanPricingTemplateInformation>
}

class PlanStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public planPricingTemplateDetails: PlanPricingTemplateInformation
    @observable public planPricingData: PlanPricingData
    @observable public searchItem: string
    @observable public page: number
    @observable public planNameId: Array<ValueLabel>
    @observable public planDefaultPricing: Array<SinglePlanPricingTemplateStore>
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.planPricingTemplateDetails = initialSinglePlanPricingTemplate
        this.planPricingData = initialTableDataWithPaginationDetails
        this.planDefaultPricing = []
    }

    @action setIsOpen = (isOpened: boolean): void => {
        this.isPageOpened = isOpened
    }

    @action resetFilter = () => {
        this.searchItem = ''
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    resetPlanPricingTemplateDetails = (): void => {
        this.planPricingTemplateDetails = {
            _id: '',
            name: '',
            plans: []
        }
        this.planDefaultPricing = []
    }

    //get the list of plan price with details
    fetchPlanPricingTemplates = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.page = page
        this.searchItem = searchItem
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getPlanPricingTemplates(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.planPricingData = response.data.data
            this.resetPlanPricingTemplateDetails()
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    // set the plan pricing template details in planDetails variable for show in update plan page
    setPlanPricingTemplateDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSinglePlanPricingTemplateDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.planPricingTemplateDetails = response.data.data
            const planDefaultPricingStores: Array<SinglePlanPricingTemplateStore> = []
            const defaultPlans = this.planPricingTemplateDetails.plans
            for (let i = 0; i < defaultPlans.length; i++) {
                const planPrice = new SinglePlanPricingTemplateStore(defaultPlans[i])
                planDefaultPricingStores.push(planPrice)
            }
            this.planDefaultPricing = planDefaultPricingStores
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the plan details of given plan_id
    addUpdateSinglePlanPricingTemplate = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const plansFromStore: Array<ExistPlan> = []
        for (let i = 0; i < this.planDefaultPricing.length; i++) {
            const plan = this.planDefaultPricing[i].get()
            plansFromStore.push(plan)
        }
        const responseData = {
            _id: data._id,
            name: data.name,
            plans: plansFromStore
        }
        return new Promise((resolve, reject) => {
            addUpdatePlanPricingTemplate(responseData).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchPlanPricingTemplates(this.page, this.searchItem)
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    fetchPlanPricingForDefault(): any {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        getPlanPricingForDefault().then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            const planDefaultPricingStores: Array<SinglePlanPricingTemplateStore> = []
            const defaultPlans = response.data.data
            for (let i = 0; i < defaultPlans.length; i++) {
                const planPrice = new SinglePlanPricingTemplateStore(defaultPlans[i])
                planDefaultPricingStores.push(planPrice)
            }
            this.planDefaultPricing = planDefaultPricingStores
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default PlanStore
