import { action, observable } from 'mobx'
import RootStore from './Root'
import { getTags, updateEntityTag } from '../api/TagActions'
import { TagInformation } from './types.d'
import { initialTableDataWithPaginationDetails } from './InitialValues'

interface TagData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<TagInformation>
}

class TagStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public tagData: TagData
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.tagData = initialTableDataWithPaginationDetails
    }

    //get the list of states with details
    fetchTag = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: page, search_item: searchItem.trim() }

        getTags(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.tagData = response.data.data
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    updateTag = (entity_name: string, entity_id: string, user_associated_tags: Array<string>): void => {
        const requestData = {
            entity_name,
            entity_id,
            user_associated_tags
        }
        
        this.isApiError = false
        this.apiErrorMessage = ''

        updateEntityTag(requestData).then((response) => {

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default TagStore
