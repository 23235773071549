import { action, observable } from 'mobx'
import RootStore from '../Root'
import { addUpdateEstateProtectPlan, getEstateProtectPlans, getSingleEstateProtectPlanDetails } from '../../api/vision/EstateProtectPlanActions'
import { EstateProtectPlanInformation, Dictionary } from '../types.d'
import { initialTableDataWithPaginationDetails, initialSingleEstateProtectPlan } from '../InitialValues'

interface EstateProtectPlanData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<EstateProtectPlanInformation>
}

class EstateProtectPlanStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public estateProtectPlanDetails: EstateProtectPlanInformation
    @observable public estateProtectPlanData: EstateProtectPlanData
    @observable public searchItem: string
    @observable public page: number
    @observable public estateProtectPlanDataHistory: Array<EstateProtectPlanData>
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.estateProtectPlanDetails = initialSingleEstateProtectPlan
        this.estateProtectPlanDataHistory = []
        this.estateProtectPlanData = initialTableDataWithPaginationDetails
    }

    resetEstateProtectPlanDetails = (): void => {
        this.estateProtectPlanDetails = {
            _id: '',
            plan_name: '',
            plan_description: '',
            display_order: 1
        }
    } 

    //get the list of plans with details
    fetchEstateProtectPlans = (page: number, searchItem: string, action: string): void => {
        this.isPageOpened = true
        if (action === 'change_page') {
            this.page = page
            for (let i = 0; i < this.estateProtectPlanDataHistory.length; i++) {
                if (this.estateProtectPlanDataHistory[i].currentPage === page) {
                    this.estateProtectPlanDataHistory.push(this.estateProtectPlanData)
                    this.estateProtectPlanData = this.estateProtectPlanDataHistory[i]
                    return
                }
            }
            this.estateProtectPlanDataHistory.push(this.estateProtectPlanData)

        } else if (action === 'search_item') {
            this.estateProtectPlanDataHistory = []
            this.searchItem = searchItem
            this.page = 1

        } else if(action === 'update' || action === 'add') {
            this.estateProtectPlanDataHistory = []
        }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getEstateProtectPlans(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.estateProtectPlanData = response.data.data
            this.estateProtectPlanDetails = initialSingleEstateProtectPlan
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the plan details in planDetails variable for show in update plan page
    setEstateProtectPlanDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSingleEstateProtectPlanDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.estateProtectPlanDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the plan details of given plan_id
    addUpdateSingleEstateProtectPlan = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdateEstateProtectPlan(data).then((response) => {
                this.isLoading = false
                
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchEstateProtectPlans(this.page, this.searchItem, 'update')
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }
}

export default EstateProtectPlanStore
