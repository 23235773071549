import { action, observable, computed } from 'mobx'
import RootStore from './Root'
import { ValueLabel } from './types.d'
import { getConditionsNameId } from '../api/ConditionActions'

class AllConditionsStore {
    public rootStore: RootStore
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public conditionNameId: Array<ValueLabel> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action resetData = () => {
        this.isLoading = false
        this.isLoaded = false
        this.conditionNameId = []
    }

    @action
    public getAllConditionsNameId() {
        if (this.isLoaded === false) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @computed get conditionNameIdWithNoneAsOption (): Array<ValueLabel> {
        const conditionNameIdWithNone : Array<ValueLabel> = (this.conditionNameId.length !== 0) ? this.conditionNameId.map((item: ValueLabel) => {
            return {
                label: item.label,
                value: item.value
            }
        }) : []
        conditionNameIdWithNone.unshift({
            value: 'none',
            label: 'None'
        })
        return conditionNameIdWithNone
    }

    @action
    public reload() {
        this.isLoading = true
        getConditionsNameId().then((response) => {
            this.conditionNameId = (response.data.data.length !== 0) ? response.data.data.map((item: any) => {
                return {
                    label: item.name,
                    value: item._id
                }
            }) : []
            this.isLoaded = true
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
        }).finally(() => {
            this.isLoading = false
        })
    }
}

export default AllConditionsStore
