import { action, observable } from 'mobx'
import RootStore from './Root'
import { getFields, getFieldDetails, updateField, getGroupFields } from '../api/FieldActions'
import SingleFieldStore from './SingleField'
import { initialField, initialTableDataWithPaginationDetails } from './InitialValues'
import { ValueLabel } from './types.d'
interface FieldInformation {
    _id?: string,
    name: string,
    type: string,
    default: string,
    variable_name: string
}

interface FieldData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<FieldInformation>
}

interface Dictionary<T> {
    // eslint-disable-next-line
    [Key: string]: T | any
}

class FieldStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public fieldDetails: SingleFieldStore
    @observable public fieldData: FieldData
    @observable public searchItem: string
    @observable public searchByTemplateFilter: ValueLabel
    @observable public page: number
    @observable public fieldDataHistory: Array<FieldData>
    @observable public isGlobal: boolean
    @observable public isConditional: boolean
    @observable public isSubField: boolean
    @observable public isHidden: boolean
    @observable public searchByTypeFilter: ValueLabel
    @observable public groupFieldsValueLabel: Array<ValueLabel>
    @observable public searchByGroupField: ValueLabel
    @observable public isPageOpened: boolean


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.fieldDetails = new SingleFieldStore(initialField)
        this.fieldData = initialTableDataWithPaginationDetails
        this.searchItem = ''
        this.searchByTemplateFilter = { value: 'All', label: 'All'}
        this.searchByTypeFilter = { value: 'All', label: 'All'}
        this.searchByGroupField = { value: 'All', label: 'All'}
        this.page = 1
        this.fieldDataHistory = []
        this.isGlobal = false
        this.isConditional = false
        this.isSubField = false
        this.isHidden = false
        this.groupFieldsValueLabel = []
    }

    @action setSearchItemField = (value: string): void => {
        this.searchItem = value
    }

    @action setDefaultGroupField = (): void => {
        this.searchByGroupField = { value: 'All', label: 'All' }
    }

    @action resetFilter = (): void => {
        this.searchItem = ''
        this.searchByTemplateFilter = { value: 'All', label: 'All'}
        this.searchByTypeFilter = { value: 'All', label: 'All'}
        this.searchByGroupField = { value: 'All', label: 'All'}
        this.isGlobal = false
        this.isConditional = false
        this.isSubField = false
        this.isHidden = false
    }

    resetFieldDetails = (): void => {
        this.fieldDetails = new SingleFieldStore(initialField)
    }

    //get the list of fields with details
    fetchField = (page: number, searchItem: string, searchFromType: ValueLabel, isGlobal: boolean, isConditional: boolean, isSubField: boolean, isHidden: boolean, searchFromTemplate: ValueLabel, groupField: ValueLabel): void => {
        this.isPageOpened = true
        this.page = page
        this.searchItem = searchItem
        this.searchByTemplateFilter = searchFromTemplate
        this.isGlobal = isGlobal
        this.isSubField = isSubField
        this.isHidden = isHidden
        this.isConditional = isConditional
        this.searchByTypeFilter = searchFromType
        this.searchByGroupField = groupField

        if (this.searchByTypeFilter.value === 'group') {
            this.getGroupFieldList(searchFromTemplate, isGlobal, isConditional, isSubField, isHidden)
        }

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        const requestData = { page: this.page, search_item: this.searchItem.trim(), search_from_template_fields: this.searchByTemplateFilter.value, is_global: this.isGlobal, is_conditional: this.isConditional, is_sub_field: this.isSubField, is_hidden: this.isHidden, search_from_type_fields: this.searchByTypeFilter.value, search_from_group_fields: this.searchByGroupField.value }

        getFields(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.isLoading = false
            this.fieldData = response.data.data
            this.resetFieldDetails()
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //get the list of group fields with details
    getGroupFieldList = (searchFromTemplate: ValueLabel, isGlobal: boolean, isConditional: boolean, isSubField: boolean, isHidden: boolean): void => {

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''
        this.searchByTemplateFilter = searchFromTemplate
        this.isGlobal = isGlobal
        this.isSubField = isSubField
        this.isHidden = isHidden
        this.isConditional = isConditional

        const requestData = { search_from_template_fields: this.searchByTemplateFilter.value, is_global: this.isGlobal, is_conditional: this.isConditional, is_sub_field: this.isSubField, is_hidden: this.isHidden }

        getGroupFields(requestData).then((response) => {
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.groupFieldsValueLabel = response.data.data.map((list: any) => {
                const data = {
                    value: list.variable_name,
                    label: list.name
                }

                return data
            })
            this.groupFieldsValueLabel.splice(0, 0, { value: 'All', label: 'All' })
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the field details in fieldDetails variable for show in update field page
    setFieldDetails = (value: string): void => {
        this.isLoading = true
        getFieldDetails(value).then((response) => {
            this.isLoading = false
            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.fieldDetails = new SingleFieldStore(response.data.data)
        }).catch((error) => {

            if (error.request.status === 401) {

                this.rootStore.authStore.setAuthToken('')

            }

            this.isLoading = false

            this.isApiError = true

            this.apiErrorMessage = error.message

        })
    }

    //update field details
    updateFieldDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updateField(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchField(this.page, this.searchItem, this.searchByTemplateFilter, this.isGlobal, this.isConditional, this.isSubField, this.isHidden, this.searchByTypeFilter, this.searchByGroupField)
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }
}

export default FieldStore
