import { action, observable } from 'mobx'
import RootStore from './Root'

class NavStore {
    public rootStore: RootStore
  @observable public collapse = false
  @observable public searchTerm: string

  constructor(rootStore: RootStore) {
      this.rootStore = rootStore
      this.resetData()
  }

  @action resetData(): void {
      this.collapse = false
      this.searchTerm = ''
  }

  setSearchTerm(newSearchTerm: string) {
      this.searchTerm = newSearchTerm
  }
}

export default NavStore 