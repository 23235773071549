import { action, observable, runInAction, computed } from 'mobx'
import RootStore from './Root'
import { getAllAppUserForInternalUse } from '../api/AppUserActions'
import { AppUserInformation } from './types.d'
import { USER_ROLE_ADMIN, USER_ROLE_ADMIN_CMS_TEAM_IPAD, USER_ROLE_EMPLOYEE, USER_ROLE_ORG_ADMIN, USER_ROLE_TEAM_CMS_IPAD } from '../utils/constant'

class AllUsersStore {
    public rootStore: RootStore
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public allAppUsers: Array<AppUserInformation> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action resetData = () => {
        this.isLoading = false
        this.isLoaded = false
        this.allAppUsers = []
    }

    @computed get iPadUserNamesWithValueLabel () {
        const filteredArray = this.allAppUsers.filter((list) => list.role === USER_ROLE_ADMIN || list.role === USER_ROLE_ADMIN_CMS_TEAM_IPAD || list.role === USER_ROLE_EMPLOYEE || list.role === USER_ROLE_TEAM_CMS_IPAD)
        const filteredArrayValueLabel = filteredArray.map((list) => {
            return {
                value: list._id || '',
                label: list.first_name + ' ' + list.last_name + ' ' + '(' + list.email + ')'
            }
        })
        return filteredArrayValueLabel
    }

    @computed get organizationOwnerNamesWithValueLabel () {
        const filteredArray = this.allAppUsers.filter((list) => list.role === USER_ROLE_ORG_ADMIN)
        const filteredArrayValueLabel = filteredArray.map((list) => {
            return {
                value: list._id || '',
                label: list.first_name + ' ' + list.last_name + ' ' + '(' + list.email + ')'
            }
        })
        return filteredArrayValueLabel
    }

    @action
    public getAllUsers() {
        if(this.isLoaded === false){
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload() {
        this.isLoading = true
        getAllAppUserForInternalUse().then((response) => {
            runInAction(() => {
                this.isLoading = false
                this.allAppUsers = response.data.data
                this.isLoaded = true
            })
        }).catch((error) => {
            runInAction(() => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
            })
        })
    }
}

export default AllUsersStore
