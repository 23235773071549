import { action, observable } from 'mobx'
import RootStore from './Root'
import { getPackageDetails, getPackages, updatePackage } from '../api/PackageActions'
import { Dictionary, PackageInformation } from './types.d'
import { initialTableDataWithPaginationDetails, initialPackage } from './InitialValues'


interface PackageData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<PackageInformation>
}

class PackageStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public packageData: PackageData
    @observable public packageDetails: PackageInformation
    @observable public searchItem: string
    @observable public page: number
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.packageDetails = initialPackage
        this.packageData = initialTableDataWithPaginationDetails
    }

    resetPackageDetails = (): void => {
        this.packageDetails = initialPackage
    }

    //get the list of Packages with details
    fetchPackage = (page: number, searchItem: string): void => {
        this.isPageOpened = true
        this.searchItem = searchItem
        this.page = page
        
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getPackages(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.resetPackageDetails()
            this.packageData = response.data.data
            
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the state details in stateDetails variable for show in update state page
    setPackageDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getPackageDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.packageDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update the package details of given package_id
    updatePackageDetails = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            updatePackage(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchPackage(this.page, this.searchItem)
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

}

export default PackageStore
