import React from 'react'
import TimeAgo from 'react-timeago'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { englishStrings } from '../lawmatics/EnglishString'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { dateFormatWithTime } from './commonFuction'
import moment from 'moment'

interface Props {
    date: string,
    id: string
}

export const ShowLastUpdateDate: React.FC<Props> = ({ date, id }) => {
    const formatter = buildFormatter(englishStrings)
    const currentDate = moment()
    const isTimeBefore2MonthOrGrater = currentDate.add(2, 'months').format('MM/dd/yyyy HH:mm:ss') > moment(date).format('MM/dd/yyyy HH:mm:ss')

    return <>
        <span className='small-font' id={id}>
            (Last Updated: {isTimeBefore2MonthOrGrater ? <TimeAgo title="" date={date} formatter={formatter}/> : <>{dateFormatWithTime(date)}</>} UTC)
        </span>
        { isTimeBefore2MonthOrGrater && <ReactTooltip place='top' anchorId={id}>
            <span className='z-3'>{dateFormatWithTime(date)} UTC</span>
        </ReactTooltip> }
    </>
}
