import { action, observable } from 'mobx'
import RootStore from './Root'
import { getTagList } from '../api/TagActions'

class AllTagStore {
    public rootStore: RootStore
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public tagList: Array<string> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action resetData () {
        this.isLoading = false
        this.isLoaded = false
        this.tagList = []
    }

    @action
    public getAllTags() {
        if (this.isLoaded === false) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload() {
        this.isLoading = true
        getTagList().then((response) => {
            this.tagList = response.data.data
            this.isLoaded = true
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
        }).finally(() => {
            this.isLoading = false
        })
    }
}

export default AllTagStore
