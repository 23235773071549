import { observable, action, computed, runInAction } from 'mobx'
import RootStore from './Root'
import { getAllFields } from '../api/FieldActions'
import { NameLabel, SingleField } from './types.d'
import { defaultOperators } from 'react-querybuilder'

class AllFieldsStore {
    public rootStore: RootStore
    @observable public apiErrorMessage = ''
    @observable public isApiError = false
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public allFieldsName: Array<SingleField> = []


    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isLoaded = false
        this.allFieldsName = []
    }

    @computed get fieldsWithValueLabel () {
        return this.allFieldsName.map((list: SingleField) => {
            const data = {
                value: list._id,
                label: list.name
            }
            return data
        })
    }

    @computed get filteredFields () {
        return this.allFieldsName.filter((list: SingleField) => list.type !== 'header' && list.type !== 'client' && list.type !== 'matter')
    }

    @computed get fieldsWithVariableNameAndName () {
        return this.filteredFields.map((list: SingleField) => {
            const data = {
                value: list.variable_name,
                label: list.name
            }
            return data
        })
    }

    @computed get filteredGroupFields () {
        return this.allFieldsName.filter((list: SingleField) =>  list.type === 'group')
    }

    @computed get groupFieldsWithVariableNameAndName () {
        return this.filteredGroupFields.map((list: SingleField) => {
            const data = {
                value: list.variable_name,
                label: list.name
            }
            return data
        })
    }

    @computed get fieldsWithVariableDescription () {
        return this.allFieldsName.map((list: SingleField) => {
            return {
                variable_name: list.variable_name,
                description: list.description
            }
        })
    }

    @computed get fieldsNameLabel () {
        this.rootStore.allStatesStore.getAllStates()
        return this.allFieldsName.map((list: any) => {
    
            let operators = defaultOperators
            let valueEditorType
            let inputType = 'text'
            let values: Array<NameLabel> = []
            let defaultValue = list.default_value
    
    
            if (list.type === 'radio') {
                const valuesInNameLabel = list.options.map(item => {
                    return {
                        name: item.value,
                        label: item.lable
                    }
                })
    
                operators = [{
                    name: '=',
                    label: '='
                }, {
                    name: '!=',
                    label: '!='
                }]
                inputType = 'radio'
                valueEditorType = 'radio'
                values = valuesInNameLabel
    
            } else if (list.type === 'drop_down') {
    
                let valuesInNameLabel = list.options.map(item => {
                    return {
                        name: item.value,
                        label: item.lable
                    }
                })
                    
                if(list.is_conditional_options === true){
                    for(let i = 0; i<list.conditional_options.length; i++){
                        const conditionalValuesInNameLabel = list.conditional_options[i].options.map(item => {
                            return {
                                name: item.value,
                                label: item.lable
                            }
                        })
                        valuesInNameLabel = valuesInNameLabel.concat(conditionalValuesInNameLabel)
                    }
                }
    
                operators = [{
                    name: '=',
                    label: '='
                }, {
                    name: '!=',
                    label: '!='
                }]
    
                if (defaultValue === '') {
                    defaultValue = valuesInNameLabel[0].name
                }
    
                valueEditorType = 'select'
                values = valuesInNameLabel
                inputType = 'select'
            } else if (list.type === 'switch') {
    
                operators = [{
                    name: '=',
                    label: '='
                }]
                inputType = 'select'
                valueEditorType = 'select'
                values = [{
                    name: 'Yes',
                    label: 'Yes'
                }, {
                    name: 'No',
                    label: 'No'
                }]
                defaultValue = values[0].name
            } else if (list.type === 'state') {
                operators = [{
                    name: '=',
                    label: '='
                }, {
                    name: '!=',
                    label: '!='
                }]
                valueEditorType = 'select'
                values = this.rootStore.allStatesStore.statesWithNameLabel
                inputType = 'select'
                defaultValue = values[0]?.name
            }
    
            const data = {
                name: list.variable_name,
                label: list.name,
                operators: operators,
                valueEditorType: valueEditorType,
                values: values,
                inputType: inputType,
                defaultValue: defaultValue,
                placeholder: list.placeholder
            }
            return data
        })
    }

    @action
    public getAllFieldsName () {
        if (this.isLoaded === false ) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload () {
        this.isLoading = true
        getAllFields().then((response) => {
            runInAction(() => {
                this.isLoading = false
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                } else {
                    this.allFieldsName = response.data.data
                    this.isLoaded = true
                }
            })
        }).catch((error) => {
            runInAction(() => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
            })
        })
    }
}

export default AllFieldsStore
