import { action, observable } from 'mobx'
import RootStore from './Root'
import { viewProfile } from '../api/SuperAdminActions'

interface OrgNameId {
    _id: string
    name: string
}
interface SuperAdminInformation {
    _id?: string,
    first_name: string,
    last_name: string,
    email: string,
    org_name: string
    org_id: OrgNameId
    role: string
}

class SuperAdminStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public superAdminData: SuperAdminInformation

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
        this.fetchCurrentUserData()
    }

    @action resetData(): void {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.superAdminData = {
            _id: '',
            first_name: '',
            last_name: '',
            email: '',
            org_name: '',
            org_id: {
                _id: '',
                name: ''
            },
            role: ''
        }
    }

    fetchCurrentUserData = (): void => {
        const currentUser = localStorage.getItem('superAdmin')

        if (currentUser === null) {
            return
        }

        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        viewProfile(currentUser).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.superAdminData = response.data.data
            this.rootStore.authStore.setUserRole(this.superAdminData.role)
            this.rootStore.authStore.setUserOrganization(this.superAdminData.org_id, false)            
        }).catch((error) => {
            console.log(error)
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }
}

export default SuperAdminStore
