import { action, observable } from 'mobx'
import RootStore from '../Root'
import { addUpdatePlan, getPlanNameId, getPlans, getSinglePlanDetails } from '../../api/vision/PlanActions'
import { PlanInformation, Dictionary, ValueLabel } from '../types.d'
import { initialTableDataWithPaginationDetails, initialSinglePlan } from '../InitialValues'
import DescriptionInfoStore from './DescriptionInfo'

interface PlanData {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<PlanInformation>
}

class PlanStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public planDetails: PlanInformation
    @observable public planData: PlanData
    @observable public searchItem: string
    @observable public page: number
    @observable public planDataHistory: Array<PlanData>
    @observable public planNameId: Array<ValueLabel>
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.planDetails = initialSinglePlan
        this.planDataHistory = []
        if (this.rootStore.authStore.isAdmin) {
            this.fetchPlanNameId()
        }
        this.planData = initialTableDataWithPaginationDetails
    }

    resetPlanDetails = (): void => {
        this.planDetails = {
            _id: '',
            name: '',
            plan_description: [],
            display_order: 1,
            price_for_single: {
                price: '',
                level_1_price: '',
                level_2_price: '',
                level_3_price: '',
                restatement_price: '',
                restatement_level_1_price: '',
                restatement_level_2_price: '',
                restatement_level_3_price: ''
            },
            price_for_married: {
                price: '',
                level_1_price: '',
                level_2_price: '',
                level_3_price: '',
                restatement_price: '',
                restatement_level_1_price: '',
                restatement_level_2_price: '',
                restatement_level_3_price: '' 
            },
            edit_button: false,
            edit_button_action: '',
            is_enabled: true,
            is_visible: true
        }
    } 

    //get the list of plans with details
    fetchPlans = (page: number, searchItem: string, action: string): void => {
        this.isPageOpened = true
        if (action === 'change_page') {
            this.page = page
            for (let i = 0; i < this.planDataHistory.length; i++) {
                if (this.planDataHistory[i].currentPage === page) {
                    this.planDataHistory.push(this.planData)
                    this.planData = this.planDataHistory[i]
                    return
                }
            }
            this.planDataHistory.push(this.planData)

        } else if (action === 'search_item') {
            this.planDataHistory = []
            this.searchItem = searchItem
            this.page = 1

        } else if(action === 'update' || action === 'add') {
            this.planDataHistory = []
        }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getPlans(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.planData = response.data.data
            this.planDetails = initialSinglePlan
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the plan details in planDetails variable for show in update plan page
    setPlanDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSinglePlanDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.planDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the plan details of given plan_id
    addUpdateSinglePlan = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        return new Promise((resolve, reject) => {
            addUpdatePlan(data).then((response) => {
                this.isLoading = false

                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchPlans(this.page, this.searchItem, 'update')
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    fetchPlanNameId(): any {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getPlanNameId().then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }
            this.planNameId = (response.data.data.length !== 0) ? response.data.data.map((item: any) => {
                return {
                    label: item.name,
                    value: item._id
                }
            }) : []
        })
    }

    addNewDescriptionInfo(type: string, text: string, image: string): void {
        const data = {
            type: type,
            text: text,
            image: image,
        }
        const descriptionInfo = new DescriptionInfoStore(data)
        this.planDetails.plan_description.push(descriptionInfo)
    }

    deleteDescriptionInfo(descriptionInfo: DescriptionInfoStore): void {
        this.planDetails.plan_description = this.planDetails.plan_description.filter((item) => item !== descriptionInfo)
    }
}

export default PlanStore
