import { action, observable } from 'mobx'
import RootStore from './Root'
import { addUpdateCommonParagraph, getCommonParagraphs, getSingleCommonParagraphDetails } from '../api/CommonParagraphAction'
import { CommonParagraphData, CommonParagraphInformation, Dictionary, StateSpecificDefault } from './types.d'
import { initialTableDataWithPaginationDetails, initialCommonParagraph } from './InitialValues'
import StateSpecificDefaultStore from './StateSpecificDefault'

interface CommonParagraphDataForTable {
    currentPage: number,
    totalPage: number,
    totalItem: number,
    itemPerPage: number,
    isFiltered: boolean,
    totalAllItems: number,
    page_items: Array<CommonParagraphData>
}

class CommonParagraphStore {
    public rootStore: RootStore
    @observable public apiErrorMessage: string
    @observable public isApiError: boolean
    @observable public isLoading: boolean
    @observable public commonParagraphDetails: CommonParagraphInformation
    @observable public commonParagraphData: CommonParagraphDataForTable
    @observable public searchItem: string
    @observable public page: number
    @observable public commonParagraphDataHistory: Array<CommonParagraphDataForTable>
    @observable public isPageOpened: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.resetData()
    }

    @action resetData = () => {
        this.apiErrorMessage = ''
        this.isApiError = false
        this.isLoading = false
        this.isPageOpened = false
        this.searchItem = ''
        this.page = 1
        this.commonParagraphDetails = initialCommonParagraph
        this.commonParagraphDataHistory = []
        this.commonParagraphData = initialTableDataWithPaginationDetails
    }

    resetCommonParagraphDetails = (): void => {
        this.commonParagraphDetails = initialCommonParagraph
    }

    //get the list of common paragraphs with details
    fetchCommonParagraphs = (page: number, searchItem: string, action: string): void => {
        this.isPageOpened = true
        if (action === 'change_page') {
            this.page = page
            for (let i = 0; i < this.commonParagraphDataHistory.length; i++) {
                if (this.commonParagraphDataHistory[i].currentPage === page) {
                    this.commonParagraphDataHistory.push(this.commonParagraphData)
                    this.commonParagraphData = this.commonParagraphDataHistory[i]
                    return
                }
            }
            this.commonParagraphDataHistory.push(this.commonParagraphData)

        } else if (action === 'search_item') {
            this.commonParagraphDataHistory = []
            this.searchItem = searchItem
            this.page = 1

        } else if (action === 'update' || action === 'add') {
            this.commonParagraphDataHistory = []
        }
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const requestData = { page: this.page, search_item: this.searchItem.trim() }

        getCommonParagraphs(requestData).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            this.resetCommonParagraphDetails()
            this.commonParagraphData = response.data.data
            this.commonParagraphDetails = initialCommonParagraph
        }).catch((error) => {

            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }

            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //set the common paragraph details in commonParagraphDetails variable for show in update common paragraph page
    setCommonParagraphDetails = (data: string): void => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getSingleCommonParagraphDetails(data).then((response) => {
            this.isLoading = false

            if (response.data.success === 0) {
                this.isApiError = true
                this.apiErrorMessage = response.data.message
                return
            }

            const stateSpecificDefault: Array<StateSpecificDefaultStore> = []
            for (let index = 0; index < response.data.data.state_specific_values.length; index++) {
                const data = new StateSpecificDefaultStore(response.data.data.state_specific_values[index])
                stateSpecificDefault.push(data)
            }
            response.data.data.state_specific_values = stateSpecificDefault
            this.commonParagraphDetails = response.data.data
        }).catch((error) => {
            if (error.request.status === 401) {
                this.rootStore.authStore.setAuthToken('')
            }
            this.isLoading = false
            this.isApiError = true
            this.apiErrorMessage = error.message
        })
    }

    //update or delete the common paragraph details of given common paragraph_id
    addUpdateSingleCommonParagraph = (data: Dictionary<string>): any => {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        const stateSpecificDefault: Array<StateSpecificDefault> = []
        for (let index = 0; index < this.commonParagraphDetails.state_specific_values.length; index++) {
            const data = this.commonParagraphDetails.state_specific_values[index]
            stateSpecificDefault.push(data.get())
        }
        data.state_specific_values = stateSpecificDefault

        return new Promise((resolve, reject) => {
            addUpdateCommonParagraph(data).then((response) => {
                this.isLoading = false
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.fetchCommonParagraphs(this.page, this.searchItem, 'update')
                resolve('true')

            }).catch((error) => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }

                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
                reject(error.message)
            })
        })
    }

    addNewStateSpecificValues(defaultValue: string, state: string, label: string): void {
        const data = {
            default_value: defaultValue,
            state: state,
            label: label
        }
        const stateSpecificDefaultValue = new StateSpecificDefaultStore(data)
        this.commonParagraphDetails.state_specific_values.push(stateSpecificDefaultValue)
    }
}

export default CommonParagraphStore
