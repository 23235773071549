import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

interface LinkProps {
	name: string
	to?: string
	isActive?: boolean
}

interface Props {
	links: Array<LinkProps>
    buttons?: any
    isMatterPage?: boolean
}

export const Breadcrumb: React.FC<Props> =({ links, buttons, isMatterPage = false }) => {

    return (
        <nav aria-label="breadcrumb" className={classNames({ 'sticky-navbar': isMatterPage })}>
            <ol className="breadcrumb align-items-center" >
                {links.map((item) => {
                    const isActive = _.get(item, 'isActive', false)
                    const name = _.get(item, 'name', '')
                    const to = _.get(item, 'to', '#')

                    if (isActive) {
                        return (
                            <li key={name} className="breadcrumb-item active styled-link" aria-current="page">
                                {name}
                            </li>
                        )
                    }
                    return (
                        <li key={name} className="breadcrumb-item" aria-current="page">
                            <Link to={to}>{name}</Link>
                        </li>
                    )
                })}
                {isMatterPage && buttons && <>{buttons}</>}
            </ol>
        </nav>
    )
}