import { observable } from 'mobx'
import ParagraphStore from './Paragraph'
import { Paragraph, Section } from './types.d'

class SectionStore implements Section {
    @observable public name: string
    @observable public description: string
    @observable public keywords: Array<string>
    @observable public is_all_states: boolean
    @observable public type: string
    @observable public states: Array<string>
    @observable public content: Array<ParagraphStore>

    constructor(section: Section) {
        this.set(section)
    }

    set(section: Section) {
        this.name = section.name
        this.description = section.description
        this.keywords = section.keywords || ['']
        this.is_all_states = section.is_all_states
        this.type = section.type
        this.states = section.states
        const content: Array<ParagraphStore> = []
        if (section.content) {
            for (let index = 0; index < section.content.length; index++) {
                const paragraph = new ParagraphStore(section.content[index])
                content.push(paragraph)
            }
        }
        this.content = content
    }

    get(): Section {
        const content: Array<Paragraph> = []
        for (let index = 0; index < this.content.length; index++) {
            const data = this.content[index]
            content.push(data.get())
        }
        return {
            name: this.name,
            description: this.description,
            keywords: this.keywords,
            is_all_states: this.is_all_states,
            type: this.type,
            states: this.states,
            content: content
        }
    }

    addNewParagraph(title: string, description: string, body: string, cb: (paragraph: ParagraphStore) => void) {
        const data = {
            name: title,
            description: description,
            is_all_states: true,
            states: [],
            body: body,
            type: 'paragraph'
        }
        const paragraph = new ParagraphStore(data)
        this.content.push(paragraph)
        cb(paragraph)
    }

    remove(paragraph: ParagraphStore) {
        const filteredData = this.content.filter(item => item !== paragraph)
        this.content = filteredData
    }
}

export default SectionStore