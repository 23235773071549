import React from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import LoginPage from './components/LoginPage'
import DashboardPage from './components/DashboardPage'
import AppUsersPage from './components/appUser/AppUsersPage'
import ChangePassword from './components/ChangePassword'
import ViewProfilePage from './components/ViewProfilePage'
import AddAppUserPage from './components/appUser/AddAppUserPage'
import UpdateAppUserPage from './components/appUser/UpdateAppUserPage'
import PageNotFound from './components/PageNotFound'
import AddStatePage from './components/state/AddStatePage'
import StatesPage from './components/state/StatesPage'
import UpdateStatePage from './components/state/UpdateStatePage'
import AddFieldPage from './components/field/AddFieldPage'
import FieldsPage from './components/field/FieldsPage'
import UpdateFieldPage from './components/field/UpdateFieldPage'
import AddTemplatePage from './components/template/AddTemplatePage'
import TemplatesPage from './components/template/TemplatesPage'
import UpdateTemplatePage from './components/template/UpdateTemplatePage'
import AddPackagePage from './components/package/AddPackagePage'
import PackagesPage from './components/package/PackagesPage'
import UpdatePackagePage from './components/package/UpdatePackagePage'
import ClientsPage from './components/client/ClientsPage'
import TemplatePreview from './components/template/PreviewTemplate'
import ClientMatters from './components/client/ClientMatters'
import PreviewDocument from './components/client/PreviewDocument'
import MatterDocuments from './components/client/MatterDocuments'
import ManageTemplateField from './components/template/ManageTemplateField'
import PluralPage from './components/pluralManage/PluralPage'
import AddUpdatePlural from './components/pluralManage/AddUpdatePlural'
import ConditionPage from './components/condition/ConditionsPage'
import AddUpdateCondition from './components/condition/AddConditionPage'
import CommonParagraphPage from './components/commonParagraph/CommonParagraphPage'
import AddUpdateCommonParagraph from './components/commonParagraph/AddUpdateCommonParagraph'
import Matters from './components/matter/MatterNotesPage'
import DeedResearch from './components/deedResearch/DeedPage'
import StylePDF from './components/setting/StylePDF'
import AppSetting from './components/setting/AppSettings'
import HtmlPreviewDocument from './components/client/HTMLPreviewDocument'
import UpdateHTMLPage from './components/client/UpdateHTMLPage'
import TrusteeInformationPage from './components/trusteeInformation/TrusteeInformationPage'
import AddUpdatePlanPage from './components/vision/plan/AddUpdatePlanPage'
import PlanPage from './components/vision/plan/PlanPage'
import ProblemQuestionPage from './components/vision/problemQuestion/ProblemQuestionPage'
import AddUpdateProblemQuestionPage from './components/vision/problemQuestion/AddUpdateProblemQuestionPage'
import EstateProtectPlanPage from './components/vision/estateProtectPlan/EstateProtectPlanPage'
import AddUpdateEstateProtectPlanPage from './components/vision/estateProtectPlan/AddUpdateEstateProtectPlanPage'
import VisionPage from './components/vision/vision/VisionPage'
import VisionDetailsPage from './components/vision/vision/VisionDetailsPage'
import ProbablePage from './components/probable/ProbablePage'
import ConnectToXeroPage from './components/ConnectToXeroPage'
import InvoicePage from './components/vision/invoice/InvoicePage'
import DeedFilingPage from './components/deedFiling/DeedFilingPage'
import ConnectToLawmaticsPage from './components/ConnectToLawmaticsPage'
import LawmaticsCronDetails from './components/lawmatics/LawmaticsInfoPage'
import PlanPricingTemplatePage from './components/vision/planPricingTemplate/PlanPricingTemplatePage'
import AddPlanPricingTemplate from './components/vision/planPricingTemplate/AddUpdatePlanPricingTemplate'
import EngagementTemplatePage from './components/vision/engagementTemplate/EngagementTemplatePage'
import AddUpdateEngagementTemplatePage from './components/vision/engagementTemplate/AddUpdateEngagementTemplatePage'
import ErrorLogsDetailsPage from './components/errorLogs/ErrorLogsDetailsPage'
import ThirdPartyLogPage from './components/thirdPartyLog/ThirdPartyLogPage'
import AddTagPage from './components/tag/AddTagPage'
import TagPage from './components/tag/TagPage'
import EmailLogsPage from './components/emailLogs/EmailLogsPage'
import AddUpdateStateSpecificHCTemp from './components/stateSpecificHCTempInfo/AddUpdateStateSpecificHCTemp'
import StateSpecificHCTempPage from './components/stateSpecificHCTempInfo/StateSpecificHCTempPage'
import AudioPage from './components/vision/audio/AudioPage'
import RootStore from './store/Root'
import ClientDraftAdjustmentPage from './components/clientDraftAdjustment/ClientDraftAdjustmentPage'
import IpadLocalDataPage from './components/iPadLocalData/IpadLocalDataPage'
import OrganizationPage from './components/organization/OrganizationPage'
import AddUpdateOrganization from './components/organization/AddUpdateOrganization'
import AddUpdateAddressPage from './components/address/AddUpdateAddress'
import AddressPage from './components/address/AddressesPage'
import AddUpdatePackagePlanPage from './components/packagePlan/AddUpdatePlanPackagePage'
import PackagePlansPage from './components/packagePlan/PackagePlanPage'

const rootStore = new RootStore()

const App: React.FC = () => {

    return <Router>
        <Switch>
            <Route exact path='/login' render={(...props) => <LoginPage {...props} rootStore={rootStore} />} />
            <Route exact path='/' render={(...props) => <DashboardPage {...props} rootStore={rootStore} />} />
            <Route exact path='/dashboard' render={(...props) => <DashboardPage {...props} rootStore={rootStore} />} />
            <Route exact path='/settings/password/edit' render={(...props) => <ChangePassword {...props} rootStore={rootStore} />} />
            <Route exact path='/settings/profile/view' render={(...props) => <ViewProfilePage {...props} rootStore={rootStore} />} />

            <Route exact path='/app-users' render={(...props) => <AppUsersPage {...props} rootStore={rootStore} />} />
            <Route exact path='/app-users/add' render={(...props) => <AddAppUserPage {...props} rootStore={rootStore} />} />
            <Route exact path='/app-users/update/:userId' render={(...props) => <UpdateAppUserPage {...props} rootStore={rootStore} />} />

            <Route exact path='/states/add' render={(...props) => <AddStatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/states/' render={(...props) => <StatesPage {...props} rootStore={rootStore} />} />
            <Route exact path='/states/update/:_id' render={(...props) => <UpdateStatePage {...props} rootStore={rootStore} />} />

            <Route exact path='/fields/add' render={(...props) => <AddFieldPage {...props} rootStore={rootStore} />} />
            <Route exact path='/fields/' render={(...props) => <FieldsPage {...props} rootStore={rootStore} />} />
            <Route exact path='/fields/update/:_id' render={(...props) => <UpdateFieldPage {...props} rootStore={rootStore} />} />

            <Route exact path='/templates/add' render={(...props) => <AddTemplatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/templates/' render={(...props) => <TemplatesPage {...props} rootStore={rootStore} />} />
            <Route exact path='/templates/update/:documentId' render={(...props) => <UpdateTemplatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/templates/update/preview/:documentId' render={(...props) => <TemplatePreview {...props} rootStore={rootStore} />} />
            <Route exact path='/templates/manage/fields/:_id' render={(...props) => <ManageTemplateField {...props} rootStore={rootStore} />} />

            <Route exact path='/packages/add' render={(...props) => <AddPackagePage {...props} rootStore={rootStore} />} />
            <Route exact path='/packages/' render={(...props) => <PackagesPage {...props} rootStore={rootStore} />} />
            <Route exact path='/packages/update/:_id' render={(...props) => <UpdatePackagePage {...props} rootStore={rootStore} />} />

            <Route exact path='/clients/' render={(...props) => <ClientsPage {...props} rootStore={rootStore} />} />
            <Route exact path='/clients/:_id' render={(...props) => <ClientMatters {...props} rootStore={rootStore} />} />
            <Route exact path='/clients/:clientId/matters/:matterId' render={(...props) => <MatterDocuments {...props} rootStore={rootStore} />} />
            <Route exact path='/clients/:clientId/matters/:matterId/preview-document/:documentId/:spouse/:UUID' render={(...props) => <PreviewDocument {...props} rootStore={rootStore} />} />
            <Route exact path='/clients/:clientId/matters/:matterId/edit-document/:documentId' render={(...props) => <UpdateTemplatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/clients/:clientId/matters/:matterId/HTML-document/:documentId/:spouse/:UUID/:isEdited' render={(...props) => <UpdateHTMLPage {...props} rootStore={rootStore} />} />
            <Route exact path='/clients/:clientId/matters/:matterId/preview-HTML-document/:documentId/:spouse/:UUID/:isEdited' render={(...props) => <HtmlPreviewDocument {...props} rootStore={rootStore} />} />
            
            <Route exact path='/probables/' render={(...props) => <ProbablePage {...props} rootStore={rootStore} />} />

            <Route exact path='/plurals/add' render={(...props) => <AddUpdatePlural {...props} rootStore={rootStore} />} />
            <Route exact path='/plurals/' render={(...props) => <PluralPage {...props} rootStore={rootStore} />} />
            <Route exact path='/plurals/update/:_id' render={(...props) => <AddUpdatePlural {...props} rootStore={rootStore} />} />

            <Route exact path='/common-paragraphs/add' render={(...props) => <AddUpdateCommonParagraph {...props} rootStore={rootStore} />} />
            <Route exact path='/common-paragraphs/' render={(...props) => <CommonParagraphPage {...props} rootStore={rootStore} />} />
            <Route exact path='/common-paragraphs/update/:_id' render={(...props) => <AddUpdateCommonParagraph {...props} rootStore={rootStore} />} />

            <Route exact path='/conditions/add' render={(...props) => <AddUpdateCondition {...props} rootStore={rootStore} />} />
            <Route exact path='/conditions/' render={(...props) => <ConditionPage {...props} rootStore={rootStore} />} />
            <Route exact path='/conditions/update/:_id' render={(...props) => <AddUpdateCondition {...props} rootStore={rootStore} />} />

            <Route exact path='/trustee-info/' render={(...props) => <TrusteeInformationPage {...props} rootStore={rootStore} />} />
            <Route exact path='/invoice/' render={(...props) => <InvoicePage {...props} rootStore={rootStore} />} />
            <Route exact path='/plans/add' render={(...props) => <AddUpdatePlanPage {...props} rootStore={rootStore} />} />
            <Route exact path='/plans/' render={(...props) => <PlanPage {...props} rootStore={rootStore} />} />
            <Route exact path='/plans/update/:_id' render={(...props) => <AddUpdatePlanPage {...props} rootStore={rootStore} />} />
            
            <Route exact path='/plan-pricing-templates/' render={(...props) => <PlanPricingTemplatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/plan-pricing-templates/add' render={(...props) => <AddPlanPricingTemplate {...props} rootStore={rootStore} />} />
            <Route exact path='/plan-pricing-templates/update/:_id' render={(...props) => <AddPlanPricingTemplate {...props} rootStore={rootStore} />} />

            <Route exact path='/problem-questions/add' render={(...props) => <AddUpdateProblemQuestionPage {...props} rootStore={rootStore} />} />
            <Route exact path='/problem-questions/' render={(...props) => <ProblemQuestionPage {...props} rootStore={rootStore} />} />
            <Route exact path='/problem-questions/update/:_id' render={(...props) => <AddUpdateProblemQuestionPage {...props} rootStore={rootStore} />} />

            <Route exact path='/estate-protect-plans/add' render={(...props) => <AddUpdateEstateProtectPlanPage {...props} rootStore={rootStore} />} />
            <Route exact path='/estate-protect-plans/' render={(...props) => <EstateProtectPlanPage {...props} rootStore={rootStore} />} />
            <Route exact path='/estate-protect-plans/update/:_id' render={(...props) => <AddUpdateEstateProtectPlanPage {...props} rootStore={rootStore} />} />
            
            <Route exact path='/visions/vision/:id' render={(...props) => <VisionDetailsPage {...props} rootStore={rootStore} />} />
            <Route exact path='/visions/vision/' render={(...props) => <VisionPage {...props} rootStore={rootStore} />} />
            <Route exact path='/visions/' render={(...props) => <VisionPage {...props} rootStore={rootStore} />} />

            <Route exact path='/deed-research/' render={(...props) => <DeedResearch {...props} rootStore={rootStore} />} />
            <Route exact path='/deed-filing/' render={(...props) => <DeedFilingPage {...props} rootStore={rootStore} />} />
            <Route exact path='/matters/' render={(...props) => <Matters {...props} rootStore={rootStore} />} />

            <Route exact path='/oath/xero' render={(...props) => <ConnectToXeroPage {...props} rootStore={rootStore} />} />
            <Route exact path='/oath/lawmatics' render={(...props) => <ConnectToLawmaticsPage {...props} rootStore={rootStore} />} />

            <Route exact path='/settings/style-pdf' render={(...props) => <StylePDF {...props} rootStore={rootStore} />} />
            <Route exact path='/client-draft-adjustments/' render={(...props) => <ClientDraftAdjustmentPage {...props} rootStore={rootStore} />} />
            <Route exact path='/organizations/settings/:id' render={(...props) => <AddUpdateOrganization {...props} rootStore={rootStore} />} />
                    
            <Route exact path='/settings/app' render={(...props) => <AppSetting {...props} rootStore={rootStore} />} />
            <Route exact path='/app-users/add/org-admin' render={(...props) => <AddAppUserPage {...props} rootStore={rootStore} />} />
            <Route exact path='/error-logs/' render={(...props) => <ErrorLogsDetailsPage {...props} rootStore={rootStore} />} />
            <Route exact path='/email-logs/' render={(...props) => <EmailLogsPage {...props} rootStore={rootStore} />} />
            <Route exact path='/lawmatics-cron-details' render={(...props) => <LawmaticsCronDetails {...props} rootStore={rootStore} />} />
            <Route exact path='/engagement-templates/' render={(...props) => <EngagementTemplatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/engagement-templates/add' render={(...props) => <AddUpdateEngagementTemplatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/engagement-templates/update/:id' render={(...props) => <AddUpdateEngagementTemplatePage {...props} rootStore={rootStore} />} />
            <Route exact path='/third-party-logs/' render={(...props) => <ThirdPartyLogPage {...props} rootStore={rootStore} />} />
            <Route exact path='/tags/add' render={(...props) => <AddTagPage {...props} rootStore={rootStore} />} />
            <Route exact path='/tags/' render={(...props) => <TagPage {...props} rootStore={rootStore} />} />
            <Route exact path='/state-specific-hc-info/' render={(...props) => <StateSpecificHCTempPage {...props} rootStore={rootStore} />} />
            <Route exact path='/state-specific-hc-info/add' render={(...props) => <AddUpdateStateSpecificHCTemp {...props} rootStore={rootStore} />} />
            <Route exact path='/state-specific-hc-info/update/:id' render={(...props) => <AddUpdateStateSpecificHCTemp {...props} rootStore={rootStore} />} />
            <Route exact path='/recordings' render={(...props) => <AudioPage {...props} rootStore={rootStore} />} />
            <Route exact path='/organizations/' render={(...props) => <OrganizationPage {...props} rootStore={rootStore} />} />
            <Route exact path='/organizations/add' render={(...props) => <AddUpdateOrganization {...props} rootStore={rootStore} />} />
            <Route exact path='/fetched-ipad-local-data/' render={(...props) => <IpadLocalDataPage {...props} rootStore={rootStore} />} />

            <Route exact path='/addresses/add' render={(...props) => <AddUpdateAddressPage {...props} rootStore={rootStore} />} />
            <Route exact path='/addresses/' render={(...props) => <AddressPage {...props} rootStore={rootStore} />} />
            <Route exact path='/addresses/update/:addressId' render={(...props) => <AddUpdateAddressPage {...props} rootStore={rootStore} />} />

            <Route exact path='/package-plans/' render={(...props) => <PackagePlansPage {...props} rootStore={rootStore} />} />
            <Route exact path='/package-plans/add' render={(...props) => <AddUpdatePackagePlanPage {...props} rootStore={rootStore} />} />
            <Route exact path='/package-plans/update/:packagePlanId' render={(...props) => <AddUpdatePackagePlanPage {...props} rootStore={rootStore} />} />

            <Route render={(...props) => <PageNotFound {...props} rootStore={rootStore} />} />
        </Switch>
    </Router>
}

export default App
