import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Dictionary } from '../store/types.d'

export const addUpdateMatterDocumentDate = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/matters/matter-date/add`
    return axios.post(url, requestData)
}

export const getMatters = (requestData: Dictionary<string>, signal: AbortSignal): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matters/`
    const config: AxiosRequestConfig = {
        signal: signal as any  // Type assertion to avoid TypeScript error
    }
    return axios.post(url, requestData, config)
}

export const editCompletedFlagInMatter = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matters/matter/edit/`
    return axios.post(url, requestData)
}

export const editNeedInfoFlagInMatter = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matters/need-info/edit/`
    return axios.post(url, requestData)
}

export const setCMSCountyInMatter = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matters/matter/edit/cms-county`
    return axios.post(url, requestData)
}

export const sendMailOfNFToClient = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/clients/client/send/name-and-fiduciary`
    return axios.post(url, requestData)
} 

export const getAutomaticEmailStatusOfMatter = (requestData: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASEURL}/matter/automatic-email-status`
    return axios.post(url, requestData)
}