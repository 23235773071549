import { action, computed, observable, runInAction } from 'mobx'
import RootStore from './Root'
import { getPackagesNameId } from '../api/PackageActions'
import { PackageInformation } from './types.d'

class AllPackagesStore {
    public rootStore: RootStore
    @observable public apiErrorMessage = ''
    @observable public isApiError = false
    @observable public isLoading = false
    @observable public isLoaded = false
    @observable public packagesNameId: Array<PackageInformation> = []

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action resetData = () => [
        this.apiErrorMessage = '',
        this.isApiError = false,
        this.isLoading = false,
        this.isLoaded = false,
        this.packagesNameId = []
    
    ]

    @computed get packagesWithAllValueLabel() {
        const initial = [{value:'ALL', label:'All'}]
        return initial.concat(this.packagesWithValueLabel)
    }

    @computed get packagesWithValueLabel() {
        return this.packagesNameId.map((list: PackageInformation) => {
            const data = {
                value: list._id,
                label: list.name
            }
            return data
        })
    }

    @action
    public getAllPackageNames() {
        if(this.isLoaded === false) {
            this.reload()
        }
    }

    @action
    public forceReload() {
        this.reload()
    }

    @action
    public reload() {
        this.isLoading = true
        this.isApiError = false
        this.apiErrorMessage = ''

        getPackagesNameId().then((response) => {
            runInAction(() => {
                this.isLoading = false
                if (response.data.success === 0) {
                    this.isApiError = true
                    this.apiErrorMessage = response.data.message
                    return
                }
                this.packagesNameId = response.data.data
                this.isLoaded = true
            })
        }).catch((error) => {
            runInAction(() => {
                if (error.request.status === 401) {
                    this.rootStore.authStore.setAuthToken('')
                }
                this.isLoading = false
                this.isApiError = true
                this.apiErrorMessage = error.message
            })
        })
    }
}

export default AllPackagesStore
