import { action, observable } from 'mobx'
import { addFieldsOfTemplate, deleteFieldFromTemplate, getFieldsOfTemplate, updateFieldsDisplayOrder } from '../api/TemplateActions'
import RootStore from './Root'
import { TemplateFieldData } from './types.d'


class TemplateFieldStore {
    public rootStore: RootStore
  @observable public templateFieldData: TemplateFieldData
  @observable public apiErrorMessage: string
  @observable public isApiError: boolean
  @observable public isLoading: boolean

  constructor(rootStore: RootStore) {
      this.rootStore = rootStore
      this.resetData()
  }

  @action resetData = () => {
      this.templateFieldData = {
          template_id: '',
          name: '',
          template_fields: []
      }
      this.apiErrorMessage = ''
      this.isApiError = false
      this.isLoading = false
  }

  getTemplateField = (templateId: string): any => {
      this.isLoading = true
      this.isApiError = false
      this.apiErrorMessage = ''

      return new Promise((resolve, reject) => {
          getFieldsOfTemplate(templateId).then((response) => {
              this.isLoading = false

              if (response.data.success === 0) {
                  this.isApiError = true
                  this.apiErrorMessage = response.data.message
                  return
              }
              this.templateFieldData = response.data.data
              resolve('true')

          }).catch((error) => {

              if (error.request.status === 401) {
                  this.rootStore.authStore.setAuthToken('')
              }

              this.isLoading = false
              this.isApiError = true
              this.apiErrorMessage = error.message
              reject(error.message)
          })
      })
  }

  addTemplateField = (requestData: any): any => {
      this.isLoading = true
      this.isApiError = false
      this.apiErrorMessage = ''

      return new Promise((resolve, reject) => {
          addFieldsOfTemplate(requestData).then((response) => {
              this.isLoading = false

              if (response.data.success === 0) {
                  this.isApiError = true
                  this.apiErrorMessage = response.data.message
                  return
              }

              resolve('true')

          }).catch((error) => {
              if (error.request.status === 401) {
                  this.rootStore.authStore.setAuthToken('')
              }

              this.isLoading = false
              this.isApiError = true
              this.apiErrorMessage = error.message
              reject(error.message)
          })
      })
  }

  updateTemplateField = (dragIndex: number, dropIndex: number): any => {
      const tempUser = this.templateFieldData.template_fields
      const dragDisplayOrder = this.templateFieldData.template_fields[dragIndex].display_order
      const dropDisplayOrder = this.templateFieldData.template_fields[dropIndex].display_order
      const [selectedRow] = tempUser.splice(dragIndex, 1)
      tempUser.splice(dropIndex, 0, selectedRow)
      const allFields = this.templateFieldData.template_fields
      const temp: Array<number> = []
      for(let i=0; i< allFields.length; i++) {

          if(allFields[i].display_order === dragDisplayOrder) {
              allFields[i].display_order = dropDisplayOrder
              temp.push(allFields[i].display_order)
          } else if(allFields[i].display_order <= dropDisplayOrder && allFields[i].display_order > dragDisplayOrder ) {
              allFields[i].display_order = allFields[i].display_order - 1
              temp.push(allFields[i].display_order)
          } else if(allFields[i].display_order >= dropDisplayOrder && allFields[i].display_order < dragDisplayOrder ) {
              allFields[i].display_order = allFields[i].display_order + 1
              temp.push(allFields[i].display_order)
          }
      }
      const requestData = {
          template_id: this.templateFieldData.template_id,
          template_fields: allFields
      }

      return new Promise((resolve, reject) => {
          updateFieldsDisplayOrder(requestData).then((response) => {
              this.isLoading = false

              if (response.data.success === 0) {
                  this.isApiError = true
                  this.apiErrorMessage = response.data.message
                  return
              }
              resolve('true')
          }).catch((error) => {

              if (error.request.status === 401) {
                  this.rootStore.authStore.setAuthToken('')
              }

              this.isLoading = false
              this.isApiError = true
              this.apiErrorMessage = error.message
              reject('false')
          })
      })
  }

  deleteField = (requestData: any): any => {
      this.isLoading = true
      this.isApiError = false
      this.apiErrorMessage = ''

      return new Promise((resolve, reject) => {
          deleteFieldFromTemplate(requestData).then((response) => {
              this.isLoading = false

              if (response.data.success === 0) {
                  this.isApiError = true
                  this.apiErrorMessage = response.data.message
                  return
              }
              this.getTemplateField(requestData.template_id)
              resolve('true')

          }).catch((error) => {
              if (error.request.status === 401) {
                  this.rootStore.authStore.setAuthToken('')
              }

              this.isLoading = false
              this.isApiError = true
              this.apiErrorMessage = error.message
              reject(error.message)
          })
      })
  }

}

export default TemplateFieldStore